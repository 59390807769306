import axios from 'axios';

const forgeryProtection = () => {
    let tokenElement = document.getElementsByName('csrf-token')[0]
    if(tokenElement)
        return {'X-CSRF-Token': tokenElement.getAttribute('content')}
    else
        return {}
}

const apiClient = axios.create({
    withCredentials: false,
    timeout: 30000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...forgeryProtection()
    }
})

export default apiClient;